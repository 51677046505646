<template>
  <div class="settings_manufacturing_boxes">
    <b-breadcrumb :items="breadcrumb" class="my-3"></b-breadcrumb>

    <b-nav tabs class="mb-3">
      <b-nav-item :active="tab=='products'" v-on:click="tab='products'">Products</b-nav-item>
      <b-nav-item v-if="tab=='current'" :active="tab=='current'">{{ loadedProduct.articlenumber }}</b-nav-item>
    </b-nav>

    <products v-if="tab=='products'" @edit="onEditProduct" @create="onCreateProduct"></products>
    <productinfo v-if="tab=='current'" :incoming="loadedProduct" @edit="onEditProduct"></productinfo>

  </div>
</template>

<script>
import products from '@/components/manufacturing.settings/boxes.products'
import productinfo from '@/components/manufacturing.settings/boxes.product'

export default {
  name: 'Products',
  components: {
    products,
    productinfo
  },
  data () {
    return {
      tab: '',
      breadcrumb: [
        {
          text: 'Home',
          to: '/'
        },
        {
          text: 'Settings',
          to: '/settings'
        },
        {
          text: 'Manufacturing',
          to: '/settings/manufacturing'
        },
        {
          text: 'Products',
          active: true
        }
      ],
      loadedProduct: null
    }
  },
  watch: {
    tab() {
      if(this.tab != 'current') {
        this.loadedProduct = null
      }
    }
  },
  methods: {
    onEditProduct(data) {
      this.tab = 'current'
      this.loadedProduct = data
    },
    onCreateProduct() {
      this.tab = 'current'
      this.loadedProduct = {id: null, articlenumber: 'New', systemreference: { id: null }, lengthvalidation: false, seriallengthmin: 0, seriallengthmax: 0, create: true}
    }
  },
  async mounted() {
    this.tab = 'products'
  }
}
</script>

<template>
<div>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12">
      <h4 class="my-3 text-muted text-uppercase">Product Information</h4>
      <b-form-group id="fieldset-1" label="Articlenumber" label-for="input-1" >
        <b-form-input id="input-1" v-model="product.articlenumber" :disabled="product.id ? true : false"></b-form-input>
      </b-form-group>
      <b-form-group id="fieldset-2" label="System Reference" label-for="input-2" >
        <b-form-select id="input-2" v-model="product.systemreference.id" :options="referenceoptions"></b-form-select>
      </b-form-group>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h4 class="my-3 text-muted text-uppercase">Validations</h4>
      <div class="row mb-2">
        <div class="col-xl-6 col-md-12">
          <b-form-checkbox
            id="checkbox-1"
            name="checkbox-1"
            value="true"
            v-model="product.lengthvalidation"
            unchecked-value="false"
          >
            Serial Length Validation
          </b-form-checkbox>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-xl-3 col-md-6 col-sm-12">
          <b-input-group v-if="product.lengthvalidation==true || product.lengthvalidation=='true'" prepend="Min">
            <b-form-input v-if="product.lengthvalidation==true || product.lengthvalidation=='true'" type="number" v-model="product.seriallengthmin"></b-form-input>
          </b-input-group>
        </div>
        <div class="col-xl-3 col-md-6 col-sm-12">
          <b-input-group v-if="product.lengthvalidation==true || product.lengthvalidation=='true'" prepend="Max">
            <b-form-input v-if="product.lengthvalidation==true || product.lengthvalidation=='true'" type="number" v-model="product.seriallengthmax"></b-form-input>
          </b-input-group>
        </div>
      </div>
      <div class="row mb-2" v-for="item in validations" v-bind:key="item.id">
        <div class="col-xl-6 col-md-12">
          <b-form-checkbox
            :value="item"
            unchecked-value=null
            v-model="selectedvalidations"
            v-on:change="toggleValidation(item)"
          >
          {{ item.name }}
          </b-form-checkbox>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <b-button v-on:click="saveProduct" variant="outline-primary"><b-icon-hdd></b-icon-hdd> {{ buttontext }}</b-button>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <span v-if="product.lastchangeddate">Changed by {{ product.lastchangedcy }}, {{ product.lastchangeddate ? product.lastchangeddate : Date.parse('1900-01-01') | moment('YYYY-MM-DD HH:mm:ss') }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { systemreferencesService, validationService, productsSettingsService } from '@/services';

export default {
  name: 'ProductInfo',
  props: [
    'incoming'
  ],
  data() {
    return {
      product: {id: null, articlenumber: '', systemreference: { id: null }, lengthvalidation: false, seriallengthmin: 0, seriallengthmax: 0},
      references: [],
      referenceoptions: [],
      validations: [],
      activevalidations: [],
      selectedvalidations: [],
      buttontext: this.incoming.create ? 'Create' : 'Save'
    }
  },
  async mounted() {
    this.product = this.incoming
    await this.loadData()
  },
  methods: {
    async getProductValidationId(validationid) {
      // Check if this validationid is activated for the product
      let productvalidationid = null
      await this.activevalidations.forEach(element => {
        if(element.validation.id === validationid) {
          productvalidationid = element.id
        }
      })
      return(productvalidationid)
    },
    async saveProduct() {

      // Save or Create a new product...
      if(this.product.id) {
        await this.updateProduct()
      } else {
        await this.createProduct()
      }

      this.loadData()
    },
    async updateProduct() {

      let product = this.product

      // Create our product body structure from form
      let json = { 
        systemreferenceid: product.systemreference.id, 
        lengthvalidation: product.lengthvalidation, 
        seriallengthmin: product.seriallengthmin, 
        seriallengthmax: product.seriallengthmax 
      }

      if(product.lengthvalidation === false || product.lengthvalidation === 'false') {
        json.seriallengthmin = 0; 
        json.seriallengthmax = 0; 
        json.lengthvalidation = false
      } else {
        json.seriallengthmin = parseInt(product.seriallengthmin); 
        json.seriallengthmax = parseInt(product.seriallengthmax); 
        json.lengthvalidation = true
      }

      // Loop validations and update/remove/add if needed
      await this.validations.forEach(async element => {
        if(element.checked === false && element.productvalidationid !== null) {
          await this.removeValidation(element.productvalidationid)
        }
        if(element.checked === true && element.productvalidationid === null) {
          await this.addValidation(element.validationid)
        }
      })

      // Save/update product informations
      await productsSettingsService.updateProduct(this.product.id, json)
        .then(() => {
          this.$bvToast.toast(`Saved changes!`, {
            title: 'Success',
            autoHideDelay: 1000,
            variant: 'success'
          })
        })
        .catch(error => {
          this.$bvToast.toast(`${JSON.stringify(error)}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })
    },
    async createProduct() {

      let product = this.product

      // Create our new product body structure from form, will be used against api
      let json = { 
        articlenumber: product.articlenumber, 
        systemreferenceid: product.systemreference.id, 
        lengthvalidation: product.lengthvalidation, 
        seriallengthmin: product.seriallengthmin, 
        seriallengthmax: product.seriallengthmax 
      }

      if(product.lengthvalidation === false || product.lengthvalidation === 'false') {
        json.seriallengthmin = 0; 
        json.seriallengthmax = 0; 
        json.lengthvalidation = false
      } else {
        json.seriallengthmin = parseInt(product.seriallengthmin); 
        json.seriallengthmax = parseInt(product.seriallengthmax); 
        json.lengthvalidation = true
      }

      let newproduct = await productsSettingsService.createProduct(json)
        .then(response => {
          this.$bvToast.toast(`Create product successful!`, {
            title: 'Success',
            autoHideDelay: 2000,
            variant: 'success'
          })
          return response
        })
        .catch(error => {
          this.$bvToast.toast(`${JSON.stringify(error)}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
          return null;
        })

      // Failed creating a new product, exit...
      if(!newproduct) return

      this.product.id = newproduct.id

      // Set chosen validations for the newly created product
      await this.validations.forEach(async element => {
        if(element.checked === true && element.productvalidationid === null) {
          await this.addValidation(element.validationid)
        }
      })

      // Change "Create" button to a "Save" button
      this.product.create = null
    },
    async toggleValidation(val) {
      await this.validations.forEach(async element => {
        if(element.validationid == val.validationid) {
          element.checked = element.checked ? false : true
        }
      })
    },
    async addValidation(validationid) {
      return await productsSettingsService.addProductValidation(this.product.id, validationid)
        .then(response => {
          return response
        })
        .catch(error => {
          this.$bvToast.toast(`${JSON.stringify(error)}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        }) 
    },
    async removeValidation(productvalidationid) {
      return await productsSettingsService.removeProductValidation(this.product.id, productvalidationid)
        .then(response => {
          return response
        })
        .catch(error => {
          this.$bvToast.toast(`${JSON.stringify(error)}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        }) 
    },
    async loadData() {

      // Get product information if user selected an existing product
      if(this.product.id != null) {
        this.product = await productsSettingsService.getById(this.product.id)
          .catch(error => {
            this.$bvToast.toast(`${JSON.stringify(error)}`, {
              title: 'Error',
              autoHideDelay: 4000,
              variant: 'danger'
            })
          })
      }

      // Get all available validations
      let vals = await validationService.getAll()
        .then(response => {
          return response.data
        })
        .catch(error => {
          this.$bvToast.toast(`${JSON.stringify(error)}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })
      
      // Get all available system references
      this.references = await systemreferencesService.getAll().catch(error => {
          this.$bvToast.toast(`${JSON.stringify(error)}`, {
            title: 'Error',
            autoHideDelay: 4000,
            variant: 'danger'
          })
        })

      // Initiate empty system reference options for combobox
      this.referenceoptions = []
      
      if(this.product.id == null) {
        let option = { 
          value: null, 
          text: 'Please select an option' 
        }
        this.referenceoptions.push(option)
      }

      // Populate system references from available system references
      await this.references.data.forEach(element => {
        let option = { value: element.id, text: element.systempath }
        this.referenceoptions.push(option)
      })
      
      let validations = this.validations = []
      let selectedvalidations = this.selectedvalidations

      // We have an existing product
      if(this.product.id) {

        // Get all selected validations for this product
        this.activevalidations = await productsSettingsService.getProductValidationsById(this.product.id)
          .then(response => {
            return response
          })
          .catch(error => {
            this.$bvToast.toast(`${JSON.stringify(error)}`, {
              title: 'Error',
              autoHideDelay: 4000,
              variant: 'danger'
            })
          })
        
        // Loop all available validations and match against selected validations for the product,
        // and update selectedvalidations array, and also update validations array. 
        // Both arrays are used for the checkbox list in the form
        await vals.forEach(async element => {
          let productvalidationid = await this.getProductValidationId(element.id)
          if(productvalidationid) {
            selectedvalidations.push({ 
              validationid: element.id, 
              name: element.name, 
              productvalidationid: productvalidationid, 
              checked: productvalidationid ? true : false 
            })
          }
          validations.push({ 
            validationid: element.id, 
            name: element.name, 
            productvalidationid: productvalidationid, 
            checked: productvalidationid ? true : false 
          })
        })
      } else {

        // There is no product loaded, user wants to create a new one
        // Populate only validations array since this is a new product
        await vals.forEach(async element => {
          validations.push({ 
            validationid: element.id, 
            name: element.name, 
            productvalidationid: null, 
            checked: false 
          })
        })

      }
    },
  }
}
</script>